// Importing necessary CSS libraries
require('bootstrap');
require('jquery-ui-dist/jquery-ui');
require('jquery-confirm');
require('datatables.net-bs5');
require('country-select-js');

import toastr from 'toastr';

import { SpartanMultiImagePicker } from 'spartan-multi-image-picker/src/js/spartan-multi-image-picker.js';

import { waitMe } from '../vendor/waitMe.js';
 
import intlTelInput from 'intl-tel-input';

const Chart = require('chart.js/auto');

/*
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

// Import basic text formatting plugins
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";

// Import essential structure plugins
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Undo from "@ckeditor/ckeditor5-undo/src/undo";

// Import image, table, and media plugins
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';

import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";

import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";

import ImageRemoveEventCallbackPlugin from 'ckeditor5-image-remove-event-callback-plugin';
*/

// jQuery
const $ = require('jquery');

(function($) {
    function DeleteRoomType(id, actionUrl) {
        toastr.warning("<button type='button' id='confirmBtn' class='btn btn-danger btn-sm' style='width:40%;display:inline;margin:3px;'>Yes</button><button type='button' id='closeBtn' class='btn btn-default btn-sm' style='width:40%;display:inline;margin:3px;'>No</button>", 'Do you want to delete this user?', {
            closeButton: true,
            allowHtml: true,
            onShown: function(toast) {
                $("#confirmBtn").click(function() {
                    $.ajax({
                        type: "POST",
                        url: actionUrl,
                        data: 'delete_id=' + id,
                        success: function(result) {
                            var responsedata = $.parseJSON(result);
                            if (responsedata.status == 'success') {
                                var table = $('#table-1').DataTable();
                                table.row('#userrow' + id).remove().draw(false);
                                toastr.success(responsedata.message)
                            } else {
                                toastr.error(responsedata.message)
                            }
                        },
                        error: function(result) {
                            toastr.error("Somthing went wrong :(")
                        }
                    });
                });
                $("#closeBtn").click(function() {
                    toastr.clear()
                });
            }
        });
    }

    function editPhoto(id) {
        //var $target = $(e.target);
        //var url = $target.attr('data-url');

        $.ajax({
            type: "POST",
            url: "get-photo",
            data: 'photo_id=' + id,
            success: function(result) {
                var responsedata = $.parseJSON(result);
                $('#list').attr('src', '');
                $('#photo_id').val(responsedata.fldPhotoID);
                $('.img-rounded-my').attr('src', '<?=PHOTO_DOMAIN?>/product/' + responsedata.fldPhotoPath + '-med.jpg');
                $("#UpdatePhotoModal").modal('show');
            },
            error: function(result) {

            }
        });
    }
    
    function slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }

    function editAccommodationView($root) {
        let $specials = $root.find('.specials');

        $specials.find('.form-field-group').sortable();

        const group = new dynamicFormGroup($specials);

        $root.find('input.slug').on('focus', function(e) {
            e.preventDefault();

            const title = $root.find('input.title').val();

            $(this).val(slugify(title));
        });
    }

    function deleteAccommodationPhotosAction($root) {

    }

    function run_waitMe(el) {
        $(el).waitMe({
          effect: 'facebook',
          text: 'Please wait...',
          bg: 'rgba(255,255,255,0.7)',
          color: '#000',
          maxSize: '',
          source: '../assets/img/img.svg',
          onClose: function() {}
        });
    }

    function uploadPhotoPopupView($root) {
        this.$root = $root;
        this.deferred = jQuery.Deferred();

        $root.find("#imageupdiv").spartanMultiImagePicker({
            fieldName:'fileUpload',
            maxCount:1,
            rowHeight:'120px',
            maxFileSize:15500000,
            allowedExt:'jpg|jpeg',
            groupClassName : 'col-md-8 col-sm-8 col-xs-8',
            placeholderImage: {image:$root.find('#cons_img').val() ,width: '60%'},
            onRenderedPreview : function(index){
              
            },
            onExtensionErr : function(index, file){
              toastr["error"]('Please only input jpg type file');
            },
            onSizeErr : function(index, file){
              toastr["error"]('This file exceeds the max size(5MB)');
            }
        });

        let $form = $root.find('form');
        const _this = this;

        $form.on('submit', function (e) {
            if (!(e.isDefaultPrevented())) {
                e.preventDefault();
                run_waitMe($form);
                var formData = new FormData(this);
                $.ajax({
                    type: "POST",
                    url: $form.attr('action'),
                    data: formData,
                    cache:false,
                    contentType: false,
                    processData: false,
                    success: function(result) {
                        toastr.success("Photo Added successfully.");

                        $form.waitMe('hide');

                        _this.deferred.resolve(result.items[0]);
                    },
                    error: function(result) {
                        $form.waitMe('hide');

                        toastr.error('Error :'+result);
                    }
                });
            }
        });
    }

    uploadPhotoPopupView.prototype.close = function () {
        this.$root.modal('hide');
    };

    uploadPhotoPopupView.prototype.add = function(itemType, itemId) {
        this.$root.find('#item_type').val(itemType);
        this.$root.find('#item_id').val(itemId);
        this.$root.find('#add_photo_id').val(0);
        this.$root.find('#photo_title').val('');
        this.$root.find('#photo_Text').val('');
        this.$root.find('#my_id').show();
        this.$root.find('#modal-titles').text('Upload Photo');

        this.deferred = jQuery.Deferred();

        this.$root.modal('show');

        return this.deferred.promise();
    }

    uploadPhotoPopupView.prototype.edit = function(photoId, photoUrl) {
        const _this = this;

        this.$root.find('#add_photo_id').val(photoId);

        this.deferred = jQuery.Deferred();

        $.ajax({
            type: "GET",
            url:  photoUrl,
            success: function(result) {
                const media = result.items[0];
                _this.$root.find('#item_type').val(media.item_type);
                _this.$root.find('#item_id').val(media.item_id);
                _this.$root.find('#photo_title').val(media.title);
                _this.$root.find('#photo_Text').val(media.description);
                _this.$root.find('#photo_Alt').val(media.alt);
                _this.$root.find('img.uploaded').attr('src', media.sizes['std']);
                _this.$root.find('#modal-titles').text('Edit Photo');
                _this.$root.modal('show');
            },
            error: function(result) {
                toastr.error(result);
            }
        });

        return this.deferred.promise();
    }

    function editAccommodationPhotosView($root) {
        const _this = this;
        
    }

    /**
     * Initialize a table with an order update via AJAX call 
     */
    function sortableItemsTable($table) {
        const $sortable = $table.find('tbody');

        $sortable.sortable({
            handle: ".drag-handle",
            placeholder: "row-placeholder",
            forcePlaceholderSize: true,
            update: function(event, ui) {
                const url = $sortable.attr('data-url');
                let order = {};

                $sortable.find('tr').each(function(index, item) {
                    const $item = $(item);
                    const id = $item.attr('data-id');
                    order[id] = index;
                });

                $.ajax({
                    type: "POST",
                    url: url,
                    data: {
                        "order": order
                    },
                    success: function(result) {
                        toastr.success("Order updated successfully.");
                    },
                    error: function(result) {
                        toastr.error("Failed to update order.");
                    }
                });
            }
        });
    }
    
    function manageItemPhotosSection($section, label) {
        let $modal = $('#addPhotoModal');
        let popup = new uploadPhotoPopupView($modal);

        let $photosTable = $section.find('table.photos');

        sortableItemsTable($photosTable);

        $section.on('click', '.upload-photo', function(e) {
            e.preventDefault();

            const $this = $(e.target);

            const itemType = $this.attr('data-item-type');
            const itemId = $this.attr('data-item-id');
            const photoId = parseInt($this.attr('data-photo-id'));
            const photoUrl = $this.attr('data-photo-url');

            const photoUploadedFunc = function(photoData) {
                popup.close();

                $this.parents('tr').find('.img-rounded').attr('src', photoData.sizes.std);
            };

            if (!photoId) {
                popup.add(itemType, itemId).done(photoUploadedFunc);
            } else {
                popup.edit(photoId, photoUrl).done(photoUploadedFunc);
            }
        });

        $photosTable.on('click', '.resize-photo', function(e) {
            const url = $(e.target).attr('data-url');
            const photoId = $(e.target).attr('data-photo-id');

            $(e.target).append('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');

            $.ajax({
                type: "POST",
                url: url,
                data: {
                    'photoId': photoId
                },
                success: function(result) {
                    $(e.target).find('.spinner-border').remove();
                    toastr.success("Photo " + photoId + " Thumbnails Regenerated Successfully.");
                },
                error: function(result) {
                    toastr.error("Somthing went wrong. Please try again.")
                }
            });
        });

        $photosTable.on('click', '.delete-photo', function(e) {
            e.preventDefault();

            handleDeleteEntity($(this), label);
        });

        $section.find('.photos').on('click', '.make-featured', function(e) {
            e.preventDefault();

            let url = $(this).attr('data-url');
            let photoId = $(this).attr('data-photo-id');

            $.ajax({
                type: "POST",
                url: url,
                data: 'photo_id=' + photoId,
                success: function(result) {
                    let message = "Update Successful";
                    toastr.success(message);

                    $root.find('.photos').find('li').each(function(index, item) {
                        let $item = $(item);
                        let id = $item.attr('data-photo-id');
                        if (id == photoId) {
                            $item.addClass('featured');
                            $item.find('.make-featured').prop('disabled', true);
                        } else {
                            $item.removeClass('featured');
                            $item.find('.make-featured').prop('disabled', false);
                        }
                    });
                },
                error: function(result) {
    
                }
            });
        });
    }
    
    function singleOfferView($root) {
        const $photosSection = $root.find('.section-photos');
        manageItemPhotosSection($photosSection, "offer photo");

        $root.find('.delete-offer').on('click', function(e) {
            e.preventDefault();

            handleDeleteEntity($(this), "offer");
        });
    }

    function editDiscountView($root) {
        ClassicEditor
            .create( document.querySelector( '.summary' ))
            .then( editor => {
            } )
            .catch( error => {
                toastr.error("Could not load editor. Please refresh the page and try again.");
                console.error( error );
            } );
    }

    function editPackageView($root) {
        ClassicEditor
            .create( document.querySelector( '.editor' ))
            .then( editor => {
            } )
            .catch( error => {
                toastr.error("Could not load editor. Please refresh the page and try again.");
                console.error( error );
            } );

        ClassicEditor
            .create( document.querySelector( '.editor-includes' ))
            .then( editor => {
            } )
            .catch( error => {
                toastr.error("Could not load editor. Please refresh the page and try again.");
                console.error( error );
            } );

        const $categorySelect = $root.find('.js-package-category');
        const $subcategorySelect = $root.find('.js-package-subcategory');

        const onCategoryChangeFunc = function() {
            const selectedCategory = $categorySelect.val();
            let selectedSubcategory = $subcategorySelect.val();

            $subcategorySelect.find('option').each(function(index, option) {
                const parentCategory = $(option).attr('data-parent');
                const isDisabled = (parentCategory != selectedCategory);
                $(option).prop('disabled', isDisabled);

                if (!selectedSubcategory.length) {
                    if (!isDisabled) {
                        selectedSubcategory = $(option).val();
                    }
                }
            });

            $subcategorySelect.val(selectedSubcategory);
        }

        $categorySelect.on('change', function(e) {
            e.preventDefault();

            onCategoryChangeFunc();
        });

        onCategoryChangeFunc();

        $root.find('input.slug').on('focus', function(e) {
            e.preventDefault();

            const title = $root.find('input.title').val();

            $(this).val(slugify(title));
        });

        const $addonActiveSwitch = $root.find('.js-addon-active');
        const $addonFieldset = $root.find('.js-addon-fieldset');

        const onAddonActiveSwitchChangeFunc = function() {
            if ($addonActiveSwitch.is(':checked')) {
                $addonFieldset.find('input').prop('disabled', false);
            } else {
                $addonFieldset.find('input').prop('disabled', true);
                $addonActiveSwitch.prop('disabled', false);
            }
        };

        $addonActiveSwitch.on('change', function(e) {
            e.preventDefault();

            onAddonActiveSwitchChangeFunc();
        });

        onAddonActiveSwitchChangeFunc();
    }

    function editOfferView($root) {
        ClassicEditor
            .create( document.querySelector( '.editor' ))
            .then( editor => {
            } )
            .catch( error => {
                toastr.error("Could not load editor. Please refresh the page and try again.");
                console.error( error );
            } );

        var offer_program_new_required = function () {
            var val = $('.js-offer-program').val();
            var type = $('.js-offer-type').val();
            var $offer_program_new = $('.js-offer-program-new');

            if (val == "new") {
                $offer_program_new.parents('.form-group').show();
                $offer_program_new.prop('required', true);
            } else {
                $offer_program_new.parents('.form-group').hide();
                $offer_program_new.prop('required', false);
            }
        }

        offer_program_new_required();

        $('.js-offer-program').change(function () {
            offer_program_new_required();
        });

        $('.js-all-packages').click(function () {
            if ($(this).is(":checked")) {
                $('.js-packages option').prop('selected', true);
            }
            else {
                $('.js-packages option').prop('selected', false);
            }
        });

        $('.js-packages').click(function () {
            $(".js-all-packages").prop("checked", false);
        });

        const $allTypesCheck = $('.js-all-types');
        if ($allTypesCheck.is(":checked")) {
            $('.js-types option').prop('selected', true);
        }
        $('.js-all-types').click(function () {
            if ($(this).is(":checked")) {
                $('.js-types option').prop('selected', true);
            }
            else {
                $('.js-types option').prop('selected', false);
            }
        });
        $('.js-types').click(function () {
            $(".js-all-types").prop("checked", false);
        });

        $root.find('input.slug').on('focus', function(e) {
            e.preventDefault();

            const title = $root.find('input.title').val();

            $(this).val(slugify(title));
        });
    }

    function listOffersView($root) {
    }

    function handleDeleteEntity($link, entityLabel) {
        const url = $link.attr('href');
        const redirectUrl = $link.attr('data-redirect-url');

        $.confirm({
            title: 'Confirm delete',
            content: 'Are you sure you wish to delete this ' + entityLabel + '?',
            buttons: {
                confirm: function () {
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: {},
                        success: function(result) {
                            $.confirm({
                                title: result.message,
                                content: "Click OK To Continue",
                                type: 'green',
                                buttons: {
                                    confirm: {
                                        text: "OK",
                                        action: function () {
                                            if (redirectUrl) {
                                                window.location.href = redirectUrl;
                                            } else {
                                                location.reload();
                                            }
                                        }
                                    }
                                }
                            });
                        },
                        error: function(result) {
                            toastr.error(result);
                        }
                    });
                },
                cancel: function() {}
            }
        });
    }

    function listEventsView($root) {
        $root.on('click', 'a.delete-event', function(e) {
            e.preventDefault();

            const $target = $(e.currentTarget);

            handleDeleteEntity($target, "event");
        });
    }

    function singleEventView($root) {
        const $photosSection = $root.find('.section-photos');
        manageItemPhotosSection($photosSection, "event photo");

        $root.on('click', 'a.delete-event', function(e) {
            e.preventDefault();

            const $target = $(e.currentTarget);

            handleDeleteEntity($target, "event");
        });
    }

    function editEventView($root) {
        ClassicEditor
            .create( document.querySelector( '.editor' ))
            .then( editor => {
            } )
            .catch( error => {
                toastr.error("Could not load editor. Please refresh the page and try again.");
                console.error( error );
            } );

        $root.find('input.slug').on('focus', function(e) {
            e.preventDefault();

            const title = $root.find('input.title').val();

            $(this).val(slugify(title));
        });
    }

    function listActivitiesView($root) {
        $root.on('click', '.delete-activity', function(e) {
            e.preventDefault();

            const $target = $(e.target);

            handleDeleteEntity($target, "activity");
        });
    }

    function editUserView($root) {
        const $permissions = $root.find('.permissions');

        $permissions.on('change', 'input', function(e) {
            e.preventDefault();

            const $input = $(e.target);
            const val = $input.val();
            const regex = new RegExp("^" + val.replace('.*', '').replace(".", "\.") + "\.[^*]");

            $permissions.find('input').each(function(index, inp) {
                let $inp = $(inp);

                if (regex.test($inp.val())) {
                    $inp.prop('disabled', $input.is(':checked'));        
                }
            });
        });
    }

    function singleUserView($root) {
        const $permissions = $root.find('.permissions');

        $permissions.on('change', 'input', function(e) {
            e.preventDefault();

            const $input = $(e.target);
            const val = $input.val();
            const regex = new RegExp("^" + val.replace('.*', '').replace(".", "\.") + "\.[^*]");

            $permissions.find('input').each(function(index, inp) {
                let $inp = $(inp);

                if (regex.test($inp.val())) {
                    $inp.prop('disabled', $input.is(':checked'));        
                }
            });

            const $form = $permissions.find('form');
            const url = $form.attr('action');
            
            $.ajax({
                type: "POST",
                url: url,
                data: $form.serialize(),
                success: function(result) {
                    
                }
            });
        });
         
        $root.find('.send-email').click(function(e) {
            e.preventDefault();

            const url = $(this).attr('href');

            $.ajax({
                type: "POST",
                url: url,
                success: function(result) {
                    toastr.success(result.message);
                    //location.reload();
                }
            })
        });
    }

    function listUsersView($root) {
        $root.find('.delete-user').on('click', function(e) {
            e.preventDefault();

            const url = $(this).attr('data-url');

            $.confirm({
                title: 'Confirm delete',
                content: 'Are you sure you wish to delete this user?',
                buttons: {
                    confirm: function () {
                        $.ajax({
                            type: "POST",
                            url: url,
                            data: {},
                            success: function(result) {
                                let message = "User Deleted Successfully.";
                                toastr.success(message);
                                location.reload();
                            },
                            error: function(result) {
                                toastr.error(result);
                            }
                        });
                    },
                    cancel: function() {}
                }
            });
        });
    }

    function listRoomTypesView($root) {
        const $table = $root.find('.table');
        sortableItemsTable($table);
    }

    function listReviewsView($root) {
        const $table = $root.find('.table');
        sortableItemsTable($table);

        $root.find('.delete-review').on('click', function(e) {
            e.preventDefault();

            const url = $(this).attr('data-url');

            $.confirm({
                title: 'Confirm delete',
                content: 'Are you sure you wish to delete this review?',
                buttons: {
                    confirm: function () {
                        $.ajax({
                            type: "POST",
                            url: url,
                            data: {},
                            success: function(result) {
                                let message = "Review Deleted Successfully.";
                                toastr.success(message);
                                location.reload();
                            },
                            error: function(result) {
                                toastr.error(result);
                            }
                        });
                    },
                    cancel: function() {}
                }
            });
        });
    }

    function initLayout() {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
    }
    
    function reportTable($tableContainer) {
        this.$tableContainer = $tableContainer;
        this.$table = $tableContainer.find('table');
        this.dtAjaxData = {};

        let options = {
            daterange: true,
            paginate: true,
            reorder: false,
            search: false,
            pageLength: 10,
            order: [[ 0, 'desc' ]]
        }

        this.dtOptions = {
            "serverSide": true
        };

        if (typeof options.order != 'undefined') {
            this.dtOptions.aaSorting = options.order;
        }

        if (typeof options.paginate != 'undefined') {
            this.dtOptions.bPaginate = true;
            this.dtOptions.paging = true;
        }

        if (typeof options.search != 'undefined' && options.search == true) {
            this.dtOptions.bFilter = true;
        } else {
            this.dtOptions.bFilter = false;
        }

        if (typeof options.pageLength != 'undefined') {
            this.dtOptions.pageLength = options.pageLength;
        }

        if (typeof options.daterange != 'undefined') {            
            $tableContainer.find('.input-daterange').datepicker({
                inline: false,
                minDate: "2019-01-01",
                maxDate: "+0d",
                dateFormat: "yy-mm-dd",
                changeYear: true
            });

            var filters = {};
    
            if ($tableContainer.find('.input-startdate').length) {
                filters.start_date = $tableContainer.find('.input-startdate').val();
            }
            if ($tableContainer.find('.input-enddate').length) {
                filters.end_date = $tableContainer.find('.input-enddate').val();
            }
            if ($tableContainer.find('.select-type').length) {
                filters.type = $tableContainer.find('.select-type').val();
            }
            if ($tableContainer.find('.select-currency').length) {
                filters.currency = $tableContainer.find('.select-currency').val();
            }
            if ($tableContainer.find('.input-search').length) {
                filters.search_qry = $tableContainer.find('.input-search').val();
            }

            this.dtAjaxData = $.extend(this.dtAjaxData, filters);
        }

        this.dtOptions.ajax = {
            url: this.$table.attr('data-fetchurl'),
            type:"POST",
            data: this.dtAjaxData
        }

        this.$table.DataTable(this.dtOptions);

        $tableContainer.on('show.bs.dropdown', function () {
            $tableContainer.css( "overflow", "inherit" );
        });

        $tableContainer.on('hide.bs.dropdown', function () {
            $tableContainer.css( "overflow", "auto" );
        });
    }

    reportTable.prototype.updateFilters = function () {
        var filters = {};

        if (this.$tableContainer.find('.input-startdate').length) {
            filters.start_date = this.$tableContainer.find('.input-startdate').val();
        } 
        if (this.$tableContainer.find('.input-enddate').length) {
            filters.end_date = this.$tableContainer.find('.input-enddate').val();
        }
        if (this.$tableContainer.find('.select-type').length) {
            filters.type = this.$tableContainer.find('.select-type').val();
        }
        if (this.$tableContainer.find('.select-status').length) {
            filters.status = this.$tableContainer.find('.select-status').val();
        }
        if (this.$tableContainer.find('.select-currency').length) {
            filters.currency = this.$tableContainer.find('.select-currency').val();
        }
        if (this.$tableContainer.find('.input-search').length) {
            filters.search_qry = this.$tableContainer.find('.input-search').val();
        }

        this.dtAjaxData = $.extend(this.dtAjaxData, filters);

        this.$table.DataTable().destroy();

        this.dtOptions.ajax.data = this.dtAjaxData;

        this.$table.DataTable(this.dtOptions);
    }
    
    reportTable.prototype.reload = function () {
        this.$table.DataTable().ajax.reload();
    }

    function initPaymentReportView($root) {
        initReportView($root);
    }

    function initReportView($root) {
        const $tableContainer = $root.find('.table-responsive');
        const $table = $tableContainer.find('table');

        const reportTableInstance = new reportTable($tableContainer);

        $tableContainer.find('.button-archive').click(function(event) {
            event.preventDefault();

            var actionUrl = $(this).attr('data-url');
            var $checked = $('.id-check:checked');

            if ($checked.length) {
                $.ajax({
                    type: "POST",
                    url: actionUrl,
                    data: $('.id-check:checked').serialize(),
                    success: function(result) {
                        reportTableInstance.reload();

                        toastr.success("Bookings archived successfully. They will be visible in 'Archived Bookings' menu.");
                    },
                    error: function(result) {
                        let json = result.responseJSON;
                        if (typeof json.debug != 'undefined') {
                            console.log(json.debug);
                        }
                        toastr.error("An error has occurred. Please try again.");
                    }
                });
            } else {
                toastr.warning("Please check some bookings first.");
            }
        });

        $tableContainer.find('.button-remove').click(function(event) {
            event.preventDefault();

            if (confirm('Are you sure you want to remove the selected bookings? They will be permanently removed.')) {
                var actionUrl = $(this).attr('data-url');
                var $checked = $('.id-check:checked');

                if ($checked.length) {
                    $.ajax({
                        type: "POST",
                        url: actionUrl,
                        data: $('.id-check:checked').serialize(),
                        success: function(result) {
                            reportTableInstance.reload();

                            toastr.success("Bookings removed successfully.");
                        }
                    });
                } else {
                    toastr.warning("Please check some bookings first.");
                }
            }
        });

        $tableContainer.find('.button-export').click(function(event) {
            event.preventDefault();

            const actionUrl = $(this).attr('data-url');

            $.ajax({
                type: "POST",
                url: actionUrl,
                data: $root.find('form').serialize(),
                success: function(result) {
                    toastr.success("CSV generated successfully.");
                }
            });
        });

        $tableContainer.find('.button-search').click(function(event) {
            event.preventDefault();

            reportTableInstance.updateFilters();
        });

    }

    function singleComponentView($root) {
        const $backlink = $root.find('.back');

        $root.find('.delete-component').on('click', function(e) {
            e.preventDefault();

            handleDeleteEntity($(this), "component");
        });

        $root.find('.publish-component').on('click', function(e) {
            e.preventDefault();

            if (confirm('Are you sure you want to publish this component?')) {
                var actionUrl = $(this).attr('href');

                $.ajax({
                    type: "POST",
                    url: actionUrl,
                    success: function(result) {
                        window.location = $backlink.attr('href');
                    }
                });
            }
        });
    }

    function dynamicFormGroup($root) {
        this.$root = $root;
        const _this = this;

        $root.on('click', '.remove', function(e) {
            e.preventDefault();
            const $target = $(e.target);
            const $group = $target.parents('.form-field-group');
            $group.remove();

            _this.reindexOptionGroups($root);
        });

        $root.find('.add').on('click', function(e) {
            e.preventDefault();
            const $groups = $root.find('.form-field-group');
            const index = $groups.length;
            const $tpl = $groups.last();
            const $cloned = $tpl.clone();

            _this.reindexOptionGroup($cloned, index, true);

            $cloned.insertAfter($tpl);
        });
    };

    dynamicFormGroup.prototype.reindexOptionGroups = function () {
        const _this = this;

        this.$root.find('.form-field-group').each(function(index, group) {
            const $group = $(group);
            _this.reindexOptionGroup($group, index, false);
        });
    };

    dynamicFormGroup.prototype.reindexOptionGroup = function ($group, index, clear) {
        if (typeof clear == 'undefined') {
            clear = false;
        }

        $group.find('.form-group').each(function(fieldGroupIndex, formGroup) {
            const $formGroup = $(formGroup);
            const $control = $formGroup.find('.form-control');
            const $help = $formGroup.find('.help-block');
            
            if (!$control.length) {
                return;
            }

            $control.attr('name', $control.attr('name').replace(/\[([0-9]+)\]/i, '[' + index + ']'));
            $control.attr('id', $control.attr('id').replace(/\_([0-9]+)\_/i, '_' + index + '_'));

            if (clear) {
                $control.val('');
            }

            if ($help.length) {
                $help.attr('id', $help.attr('id').replace(/\_([0-9]+)\_/i, '_' + index + '_'));
            }
        });
    };

    function editComponentView($root) {
        const $form = $root.find('form');
        const $optionsFieldset = $form.find('fieldset.options');
        
        const dynamicGroup = new dynamicFormGroup($optionsFieldset);
    }

    function editComponentInstanceView($root) {
        let $modal = $('#addPhotoModal');
        let popup = new uploadPhotoPopupView($modal);

        $root.on('click', '.upload-photo', function(e) {
            e.preventDefault();

            const $this = $(e.target);

            const itemType = $this.attr('data-item-type');
            const itemId = $this.attr('data-item-id');
            const photoId = parseInt($this.attr('data-photo-id'));
            const photoUrl = $this.attr('data-photo-url');

            const $optionValueField = $this.parents('.form-group').find('.option-image');

            const photoUploadedFunc = function(photoData) {
                popup.close();

                $optionValueField.val(photoData.id);
            };

            if (!photoId) {
                popup.add(itemType, itemId).done(photoUploadedFunc);
            } else {
                popup.edit(photoId, photoUrl).done(photoUploadedFunc);
            }
        });
    }

    function editReviewView($root) {
        ClassicEditor
            .create( document.querySelector( '.editor' ))
            .then( editor => {
            } )
            .catch( error => {
                toastr.error("Could not load editor. Please refresh the page and try again.");
                console.error( error );
            } );
    }
    
    function singleAccommodationView($root) {
        const $photosSection = $root.find('.section-photos');
        manageItemPhotosSection($photosSection, "accommodation photo");        
    }
    
    function initDashboardView($root) {
        $root.find('.calendar').datepicker();

        const $canvas = $root.find('.sales-chart');
        const actionUrl = $canvas.attr('data-url');

        $.ajax({
            type: "GET",
            url: actionUrl,
            success: function(result) {
                const data = result.data;

                var salesGraphChartData = {
                    labels: data.labels,
                    datasets: [{
                        label: 'Payments',
                        data: data.values
                    }]
                }

                var salesGraphChartOptions = {

                    plugins: {
                        subtitle: {
                            display: true,
                            text: 'This chart illustrates total sales per each quarter since the first payment was made to the last one.'
                        }
                    },
                    legend: {
                        display: false
                    }
                }

                var salesGraphChart = new Chart($canvas[0],{
                    type: 'line',
                    data: salesGraphChartData,
                    options: salesGraphChartOptions
                })
            }
        });
    }

    function initPageSectionView($root) {
        const $photosSection = $root.find('.section-photos');
        manageItemPhotosSection($photosSection, "page photo");
    }
    
    function editPageSectionView($root) {
        ClassicEditor
            .create( document.querySelector( '.editor' ), {
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        {
                            model: 'paragraphLead',
                            view: {
                                name: 'p',
                                classes: 'lead'
                            },
                            title: 'Lead Paragraph',
                            class: 'ck-heading_paragraph_lead'
                        }
                    ]
                }
            })
            .then( editor => {
            } )
            .catch( error => {
                toastr.error("Could not load editor. Please refresh the page and try again.");
                console.error( error );
            } );
    }
    
    function initListUserRoles($root) {
        $root.find('.delete').on('click', function(e) {
            e.preventDefault();

            const $link = $(e.target);
            const itemId = $link.attr('data-item-id');

            handleDeleteEntity($link, "User Role");
        });
    }

    function initPageView($root) {
        $root.find('.delete-page-section').on('click', function(e) {
            e.preventDefault();

            handleDeleteEntity($(this), "Page Section");
        });

        const $table = $root.find('table.page-sections');
        sortableItemsTable($table);
    }

    function string_to_slug (str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
      
        // remove accents, swap ñ for n, etc
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to   = "aaaaeeeeiiiioooouuuunc------";
        for (var i=0, l=from.length ; i<l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }

    function initSingleBlogArticleView($root) {
        $root.find('.delete').on('click', function(e) {
            e.preventDefault();

            const $link = $(e.target);

            handleDeleteEntity($link, "Blog Article");
        });

        $root.find('.publish').click(function(e) {
            e.preventDefault();

            const url = $(this).attr('href');

            $.ajax({
                type: "POST",
                url: url,
                success: function(result) {
                    toastr.success(result.message);
                    
                   // location.reload();
                },
                error: function() {
                    toastr.error("Could not publish article.");
                }
            })
        });
    }

    function initPendingAvifView($root) {
        const $modal = $('.message-modal');
        const $table = $root.find('.table');
        const myModal = new bootstrap.Modal($modal);
        const $modalBody = $modal.find('.modal-body');
        
        let hide = false;

        $modal.on('hide.bs.modal', function() {
            $modalBody.find('.processing').remove();
        });
        
        $modal.on('shown.bs.modal', function() {
           if (hide) {
               myModal.hide();
               hide = false;
           }
        });

        var processRow = function($row, index) {
            $modalBody.find('.processing .value').html(index);

            const $link = $row.find('.regenerate');
            const url = $link.attr('href');

            $.ajax({
                type: "POST",
                url: url,
                success: function(result) {
                    toastr.success(result.message);

                    const $next = $row.next('tr');

                    $row.remove();

                    if ($next.length) {
                        processRow($next, index+1);
                    } else {
                        $modalBody.find('.processing').remove();
                        myModal.hide();
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    const json = jqXHR.responseJSON;

                    toastr.error(json.message);
                },
                complete: function() {
                }
            });
        };

        $root.find('.generate-all').click(function(e) {
            const currentRowIndex = 0;
            const $rows = $table.find('tbody tr');
            const $firstRow = $rows.first();

            $modalBody.append('<p class="processing">Processing: <span class="value"></span> of <span class="total"></span> ...</p>');
            $modalBody.find('.processing .total').html($rows.length);

            myModal.show();

            processRow($firstRow, 1);

        });

        $root.on('click', '.regenerate', function(e) {
            e.preventDefault();

            myModal.show();

            const $link = $(e.target);
            const url = $link.attr('href');

            $.ajax({
                type: "POST",
                url: url,
                success: function(result) {
                    toastr.success(result.message);

                    $link.parents('tr').remove();
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    const json = jqXHR.responseJSON;

                    toastr.error(json.message);
                },
                complete: function() {
                    hide = true;
                    myModal.hide();
                }
            });
        });
    }

    function initEditBlogArticleView($root) {
        const itemId = $root.find('input#item-id').val();
        
        const $title = $root.find('.js-title');
        const $slug = $root.find('.js-slug');

        const $textarea = $root.find('.editor');

        $title.on('blur', function() {
            $slug.val(string_to_slug($title.val()));
        });

        if ($textarea.length) {
            ClassicEditor
                .create( document.querySelector( '.editor' ), {
					/*
					licenseKey: 'GPL',
					plugins: [
						Paragraph, Bold, Italic, Link, List, Indent, BlockQuote, Undo,
						Image, ImageToolbar, ImageUpload, ImageStyle, ImageResize, 
						Table, TableToolbar,
						MediaEmbed, ImageRemoveEventCallbackPlugin
					],
					toolbar: [
						"bold", "italic", "link", "bulletedList", "numberedList", "|", // SEPARATOR
						"outdent", "indent", "|", // SEPARATOR
						"insertImage", "blockQuote", "insertTable", "mediaEmbed", "|", // SEPARATOR
						"undo", "redo"
					],
					image: {
						toolbar: ["imageStyle:full", "imageStyle:side", "|", "imageTextAlternative"],
						styles: ['full', 'alignLeft', 'alignCenter', 'alignRight']
					},
					table: {
						contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
					},
					imageRemoveEvent: {
						additionalElementTypes: null, // Add additional element types to invoke callback events. Default is null and it's not required. Already included ['image','imageBlock','inlineImage']
						// additionalElementTypes: ['image', 'imageBlock', 'inlineImage'], // Demo to write additional element types
						callback: (imagesSrc, nodeObjects) => {
							// note: imagesSrc is array of src & nodeObjects is array of nodeObject
							// node object api: https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_model_node-Node.html

							console.log('callback called', imagesSrc, nodeObjects)
						}
					}
					*/
                    simpleUpload: {
                        uploadUrl: $textarea.attr('data-uploadurl') + '?itemId=' + itemId
                    },
                })
                .then( editor => {
                } )
                .catch( error => {
                    toastr.error("Could not load editor. Please refresh the page and try again.");
                    console.error( error );
                } );
        }

        $root.find('form').on('submit', function(e) {
            
        });
    }

    function editPaymentRequest($root) {

        const $mobileInput = $root.find('input.mobile');

        intlTelInput($mobileInput[0], {
            utilsScript: '/assets/front/vendor/intl-utils.js',
            preferredCountries: $mobileInput.attr('data-preferredcountries').split(',')
        });

        const $countryInput = $root.find('#item-country');
        let options = {};

        $countryInput.on('change', function(e) {
            var countryData = $(this).countrySelect("getSelectedCountryData");
            $root.find('.country-iso').val(countryData.iso2);
        });

        options.defaultCountry = $countryInput.attr('data-defaultcountry');
        options.preferredCountries = $countryInput.attr('data-preferredcountries').split(',');
        options.responsiveDropdown = true;

        $root.find('#item-country').countrySelect(options);
    }

    function singlePaymentRequestView($root) {
        $root.find('.pay').click(function(e) {
            e.preventDefault();

            const url = $(this).attr('href');

            $.ajax({
                type: "POST",
                url: url,
                success: function(result) {
                    toastr.success(result.message);

                    var form = document.createElement("form");
                    form.setAttribute("method", "POST");
                    form.setAttribute("name", "booknow");
                    form.setAttribute("action", result.data.cybersource.url);

                    for (let key in result.data.cybersource.params) {
                        let value = result.data.cybersource.params[key];
                        let hiddenField = document.createElement("input");
                        hiddenField.setAttribute("type", "hidden");
                        hiddenField.setAttribute("name", key);
                        hiddenField.setAttribute("value", value);
                        form.appendChild(hiddenField);
                    }

                    document.body.appendChild(form);
                    form.submit();
                }
            })
        });

        const $form = $root.find('.test-acceptance-form');

        $form.submit(function(e) {
            e.preventDefault();

            const url = $(this).attr('action');

            $.ajax({
                type: "POST",
                url: url,
                data: $(this).serialize(),
                success: function(result) {
                    $('.output').html(result);
                    toastr.success("Request sent successfully.");
                },
                error: function(result) {
                    $('.output').html(result.responseText);
                    toastr.error("An error has occurred.");
                }
            })
        });

        $root.find('.send-email').click(function(e) {
            e.preventDefault();

            const url = $(this).attr('href');

            $.ajax({
                type: "POST",
                url: url,
                success: function(result) {
                    toastr.success(result.message);
                    //location.reload();
                }
            })
        });
    }

    function initSingleRatePeriodView($root) {
        const $filterRatesForm = $root.find('.filter-rates');
        const $dateField = $filterRatesForm.find('.date');
        const $weekdayField = $filterRatesForm.find('.weekday');

        $filterRatesForm.find('.weekday').on('change', function(e) {
            const val = $(this).val();

            if (val != 0) {
                $dateField.val("");
            }
        });

        $filterRatesForm.find('.date').on('change', function(e) {
            const val = $(this).val();

            if (val != 0) {
                $weekdayField.val("0");
            }
        });


        $root.find('.delete-rate-period').on('click', function(e) {
            e.preventDefault();

            handleDeleteEntity($(this), 'rate period');
        });

        $root.find('.publish-rate-period').on('click', function(e) {
            e.preventDefault();
            
            const url = $(this).attr('href');

            $.confirm({
                title: 'Confirm Publish',
                content: 'Are you sure you wish to publish this rate period? Once published, rates will no longer be editabled and will appear in the front-end.',
                buttons: {
                    confirm: function () {
                        $.ajax({
                            type: "POST",
                            url: url,
                            data: {},
                            success: function(result) {
                                toastr.success(result.message);
                                location.reload();
                            }
                        })
                    },
                    cancel: function () {}
                }
             });
        });

        const $form = $root.find('form.update-rates');
        const $table = $form.find('table.rates');

        const $currencySelect = $form.find('select.currency');
        $currencySelect.on('change', function() {
            const val = $(this).val();
            $table.hide();
            const $filtered = $table.filter('[data-currency=' + val + ']');
            $filtered.show();
        });

        $currencySelect.change();

        $table.on('click', '.btn-reset', function(e) {
            const actionUrl = $form.attr('data-reset-action');

            const $btn = $(this);
            let key = $btn.attr('data-key');

            let data = {};
            data['key'] = key;
            data['currency_code'] = $form.find('.currency').val();
            data['weekday'] = $form.find('.weekday').val();
            data['date'] = $form.find('.date').val();

            $.ajax({
                type: "POST",
                url: actionUrl,
                data: data,
                success: function(result) {
                    toastr.success("Rate amounts reverted successfully.");

                    $btn.parents('tr').find('input').each(function(index, input) {
                        let $input = $(input);
                        $input.val('');
                    });
                }
            });
        });

        $table.on('focus', 'input', function(e) {
            const $input = $(e.target);
            $input.prop('old', $input.val());
        });

        $table.on('blur', 'input', function(e) {
            const actionUrl = $form.attr('action');
            const $input = $(e.target);
            
            if ($input.prop('old') == $input.val()) {
                return;
            }

            let data = {};
            data[$input.attr('name')] = $input.val();
            data['currency_code'] = $form.find('.currency').val();
            data['weekday'] = $form.find('.weekday').val();
            data['date'] = $form.find('.date').val();

            $.ajax({
                type: "POST",
                url: actionUrl,
                data: data,
                success: function(result) {
                     toastr.success("Rate updated successfully.");
                }
            });
        });
    }

    function singleMediaFolderView($root) {
        const $photosTable = $root.find('table.photos');
        $photosTable.on('click', '.regenerate-thumbnails', function(e) {
            e.preventDefault();

            const $trigger = $(e.target);
            
            if ($trigger.is('.loading')) {
                return;
            }

            const photoId = $trigger.attr('data-photo-id');
            const url = $trigger.attr('href');

            $trigger.append('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>').addClass('loading');

            $.ajax({
                type: "POST",
                url: url,
                data: {
                    'photoId': photoId
                },
                success: function(result) {
                    $trigger.find('.spinner-border').remove().removeClass('loading');

                    index += 1;
                    toastr.success("Image thumbnails regenerated successfully.");
                },
                error: function(result) {
                    toastr.error("Somthing went wrong :(")
                }
            });
        });

        $root.find('.regenerate-all').on('click', function(e) {
            e.preventDefault();

            const url = $(this).attr('href');

            const $photosTable = $root.find('table.photos');
            const photosCount = $photosTable.find('tbody tr').length;
            let $current = $photosTable.find('tbody tr:first');
            let index = 0;
            
            const resizeMediaPhoto = function($row, url) {
                $.ajax({
                    type: "POST",
                    url: url,
                    data: {
                        'photoId': $row.attr('data-id')
                    },
                    success: function(result) {
                        index += 1;
                        toastr.success("Resized Image " + index + " of " + photosCount);

                        $row = $row.next();
                        if ($row.length) {
                            resizeMediaPhoto($row, url);
                        }
                    },
                    error: function(result) {
                        toastr.error("Somthing went wrong :(")
                    }
                });
            }
            resizeMediaPhoto($current, url);
        });
    }

    function initPagesView($root) {
        const $table = $root.find('.table');

        $table.on('click', '.delete-page', function(e) {
            e.preventDefault();

            $target = $(e.target);

            handleDeleteEntity($target, "Page");
        });
    }

    $(function() {
        initLayout();

        const $panel = $('.panel');
        
        if ($panel.is('.dashboard')) {
            initDashboardView($panel);
        }

        if ($panel.is('.view-pending-avif')) {
            initPendingAvifView($panel);
        }

        if ($panel.is('.view-rate-period')) {
            initSingleRatePeriodView($panel);
        }

        if ($panel.is('.single-blog-article')) {
            initSingleBlogArticleView($panel);
        }

        if ($panel.is('.edit-blog-article')) {
            initEditBlogArticleView($panel);
        }

        if ($panel.is('.view-user-roles')) {
            initListUserRoles($panel);
        }
     
        if ($panel.is('.view-pages')) {
            initPagesView($panel);
        }

        if ($panel.is('.single-page')) {
            initPageView($panel);
        }
        
        if ($panel.is('.single-page-section')) {
            initPageSectionView($panel);
        }
        
        if ($panel.is('.edit-page-section')) {
            editPageSectionView($panel);
        }
        
        if ($panel.is('.view-folder')) {
            singleMediaFolderView($panel);
        }

        if ($panel.is('.single-payment-request')) {
            singlePaymentRequestView($panel);
        }
        
        if ($panel.is('.edit-payment-request')) {
            editPaymentRequest($panel);
        }
        
        if ($panel.is('.edit-discount')) {
            editDiscountView($panel);
        }
        
        if ($('.view-report').length) {
            initReportView($('.view-report'));
        }

        if ($('.view-payments-report').length) {
            initPaymentReportView($('.view-payments-report'));
        }
        
        if ($('.single-user').length) {
            singleUserView($('.single-user'));
        }

        if ($('.edit-user').length) {
            editUserView($('.edit-user'));
        }
    
        if ($('.view-users').length) {
            listUsersView($('.view-users'));
        }

        if ($panel.is('.view-events')) {
            listEventsView($('.view-events'));
        }

        if ($panel.is('.single-event')) {
            singleEventView($('.single-event'));
        }

        if ($panel.is('.edit-event')) {
            editEventView($('.edit-event'));
        }

        if ($('.view-activities').length) {
            listActivitiesView($('.view-activities'));
        }

        if ($('.single-offer').length) {
            singleOfferView($('.single-offer'));
        }

        if ($('.view-offers').length) {
            listOffersView($('.view-offers'));
        }

        if ($('.single-component').length) {
            singleComponentView($('.single-component'));
        }

        if ($('.edit-component').length) {
            editComponentView($('.edit-component'));
        }

        if ($('.edit-component-instance').length) {
            editComponentInstanceView($('.edit-component-instance'));
        }

        if ($('.edit-package').length) {
            editPackageView($('.edit-package'));
        }

        if ($('.edit-offer').length) {
            editOfferView($('.edit-offer'));
        }

        if ($('.view-accommodation').length) {
            singleAccommodationView($('.view-accommodation'));
        }

        if ($('.edit-accommodation').length) {
            editAccommodationView($('.edit-accommodation'));
        }

        if ($('.edit-accommodation-photos').length) {
            editAccommodationPhotosView($('.edit-accommodation-photos'));
        }

        if ($('.view-reviews').length) {
            listReviewsView($('.view-reviews'));
        }
        
        if ($('.view-room-types').length) {
            listRoomTypesView($('.view-room-types'));
        }

        if ($('.panel.edit-review').length) {
            editReviewView($('.edit-review'));
        }
 
        $('#table-1').DataTable({
            "pageLength": 25,
            "bSort": false
        });
    });
})(jQuery);